<template>
  <b-container fluid="">
    <b-row class="pt-2">
      <b-col>
        <b-form-select v-model="selected" :options="options" style="min-width: 150px;"></b-form-select>
        <b-button size="sm" style="margin-left: 10px" v-on:click="buildClient">Run Build</b-button>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <b-form-textarea
            id="textarea"
            placeholder="Logs"
            v-model="log"
            rows="30"
            max-rows="30"
            disabled
        ></b-form-textarea>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      selected: null,
      log: '',
      options: []
    }
  },
  mounted() {
    fetch("http://localhost:8080/clients").then((response) => {
      return response.json();
    })
    .then((data) => {
      data.forEach(r => {
        this.options.push({value: r, text: r})
      });
    });
  },
  updated() {
    this.$nextTick(function () {
      const textarea = document.getElementById("textarea");
      if(textarea.selectionStart === textarea.selectionEnd) {
        textarea.scrollTop = textarea.scrollHeight;
      }
    })
  },
  methods: {
    buildClient: async function () {
      this.log = '';
      let stream = await fetch('http://localhost:8080/build/' + this.selected);
      const reader = stream.body.getReader();
      let dec = new TextDecoder();

      // eslint-disable-next-line no-constant-condition
      while(true) {
        const {done, value} = await reader.read();
        if (done) {
          break;
        }

        this.log += dec.decode(value);
      }
    }
  }
}
</script>

<style>
html, body {
  min-height: 100% !important;
  height: 100%;
}
</style>
